import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "dictionary"
    }}>{`Dictionary`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Word`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ticket Medium`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A physical medium that may contain one or more tickets. For example, a mobile phone or a DesFire card.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Capabilities`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A text that identifies resting properties a given NOD Client possesses. NOD Customizes the NOD Client Commands to the Client's Capabilites`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`DIS`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Detailed Interface Specification. Detailed description of, among other things, the transaction format used for electronic ticketing`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Travelers`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A person traveling with a public ticket.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`HB206`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Abbreviation for Handbook 206.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Image`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A representation of the contents of a ticket medium, eg the contents of a Travel Card or a QR Code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Interoperable`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Property of a ticket if PTOs can verify the validity of the ticket independently.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Client command`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A simple, low-level command from NOD to NOD Client that the client must support. For example, "View Message" or "View Mobile Ticket". Client commands can be combined to perform complex scenarios without the NOD Client needing to be upgraded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Logical Order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A representation of an Order that focuses on information and purpose rather than being tied to the physical representation of the Order on a Ticket Medium.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`NOD`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`National Order Database. National platform for distribution of tickets.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`NOD Backsystem`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Services used by PTOs for the administration of orders distributed by the NOD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`NOD Client`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Functionality of a device that a Traveler uses to carry out a Pickup. A NOD Client can be part of a larger system, for example combined with a sales solution. Examples are a Mobile APP or a PDO.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Nortic Specification DESFire (NSD)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specification for the data structures of the DESFire cards used for electronic ticketing in Norway.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Online Service`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A type of functionality that depends on being connected to the internet to work.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A document that tells what a given Plugin should change on an Image.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`OrderGroup`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A collection Order to be performed in a single operation. May contain Orders executed by different Plugins.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Pickup`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The process by which a NOD Client initiates and executes an OrderGroup in the form of a series of simple client commands.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`PL4`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Existing back system for PTOs owned by IO. The NOD back system services (eg for ordering orders) are exposed through this system.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Plugin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An independent module that extends NOD's functionality.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`PTO`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Public Transport Operator. A public transport operator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`PUD`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Pick Up Device. Specialized entity that allows Travelers to execute Orders posted via other channels (eg, a WEB based sales solution).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Travel Card`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The electronic travel card used in Norway, based on the DESFire standard.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales Solution`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Service for the traveler who sells a product and puts it out for distribution in NOD as an OrderGroup. The sales solution is not part of NOD.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Static OrderGroup`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An Order Group with a unique name that a NOD Client can retrieve on their own initiative to perform special actions such as displaying card content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Transaction`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A document produced by a Plugin under Pickup. This document can be distributed back to PTO or Clearing Systems.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`TVM`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Counter Vending Machine. Specialized unit that contains both a Sales solution and a NOD Client. An Order is executed immediately after the sale and is perceived by the Traveler as one system.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      